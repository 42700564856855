import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Services.css";

function Services() {
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.leadqode.com/api/info/getallinfo")
      .then((response) => {
        const servicesInfo = response.data.filter(
          (info) => info.type === "services"
        );

        const repeatedData = [
          ...servicesInfo,
          ...servicesInfo,
          ...servicesInfo,
          ...servicesInfo,
          ...servicesInfo,
          ...servicesInfo,
        ];

        const modifiedData = repeatedData.map((item) => ({
          title: item.details[0].title,
          subtitle: item.details[0].subtitle,
        }));

        setServicesData(modifiedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="marquee_container_services">
      {servicesData.map((service, index) => (
        <div key={index} className="custom_marquee_services">
          <div className="marquee_title_services">
            <h3>
              <span>{service.subtitle +" "}</span>  {service.title}
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Services;
