import React from "react";
import "./Hosting.css";
import Fadeonscroll from "../../../Components/FadeOnScroll/Fadeonscroll";
import { useNavigate } from "react-router-dom";

function PricingItem({
  currency,
  price,
  period,
  title,
  features,
  featured,
  chooseplan,
}) {
  const cardClassName = featured
    ? "pricing-item features-item ja-animate pricing__item--featured"
    : "pricing-item features-item ja-animate";

  const handlePlan = () => {
    window.location.href = `${chooseplan}`;
  };

  return (
    <div className={cardClassName}>
      <div className="pricing-deco">
        {/* Pricing deco SVG */}
        <div className="pricing-price">
          <span className="pricing-currency">{currency}</span>
          {price}
          <span className="pricing-period">{period}</span>
        </div>
        <h3 className="pricing-title">{title}</h3>
      </div>
      <ul className="pricing-feature-list">
        {features.map((feature, index) => (
          <li className="pricing-feature" key={index}>
            {feature}
          </li>
        ))}
        <button onClick={handlePlan} value={chooseplan} className="pricing_btn">
          Choose Plan
        </button>
      </ul>
    </div>
  );
}

function Hosting() {
  const navigate = useNavigate();

  const handlecklicContact = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/contact-us");
    }, 1000);
  };
  const pricingItems = [
    {
      currency: "$",
      price: 59.99,
      period: "/ year",
      title: "Basic",
      features: [
        "1 Website",
        "50GB Storage",
        "UNLIMITED Traffic",
        "2GB Physical Ram",
        "5 email accounts 5GB/ Inbox",
        "3 MySQL Databases",
        "50 MB IO",
        "FREE .com / .net / .org / .me / .biz (for the first year)",
        "FREE Migration",
      ],
      chooseplan:
        "https://portal.leadqode.com/index.php?rp=/store/cloud-hosting",
    },
    {
      currency: "$",
      price: 99.99,
      period: "/ year",
      title: "Plus",
      features: [
        "1 Website",
        "100GB Storage",
        "UNLIMITED Traffic",
        "2GB Physical Ram",
        "12 email accounts 5GB/ Inbox",
        "5 MySQL Databases",
        "50 MB IO",
        "FREE .com / .net / .org / .me / .biz (for the first year)",
        "FREE Migration",
      ],
      featured: true,
      chooseplan:
        "https://portal.leadqode.com/index.php?rp=/store/cloud-hosting",
    },
    {
      currency: "$",
      price: 149.99,
      period: "/ year",
      title: "Pro",
      features: [
        "3 Website",
        "UNLIMITED Storage",
        "UNLIMITED Traffic",
        "2GB Physical Ram",
        "50 email accounts 5GB/ Inbox",
        "15 MySQL Databases",
        "50 MB IO",
        "FREE .com / .net / .org / .me / .biz (for Lifetime of account)",
        "FREE Migration",
      ],
      chooseplan:
        "https://portal.leadqode.com/index.php?rp=/store/cloud-hosting",
    },
  ];

  return (
    <div>
      <Fadeonscroll />
      <div className="overflow_hidden">
      <div className="page_title flex">
            <span>HOSTING </span> <h1>DOMAINS</h1> <span>HOSTING </span>{" "}
            <h1>DOMAINS</h1> <span>HOSTING </span> <h1>DOMAINS</h1>{" "}
            <span>HOSTING </span> <h1>DOMAINS</h1> <span>HOSTING </span>{" "}
            <h1>DOMAINS</h1>{" "}
          </div>{" "}

</div>
      <div className="hosting_form_container hidden">
        <p>Search for you Domain Name</p>
        <form
          className="domain_form"
          action="https://portal.leadqode.com/cart.php?a=add&domain=register"
          method="post"
        >
          <input className="input_form" type="text" name="query" size="20" />

          <input className="domain_btn" type="submit" value="Go" />
        </form>

        {/* <p>Choose your Plan</p> */}
        <div className="pricing pricing-palden">
          {pricingItems.map((item, index) => (
            <PricingItem key={index} {...item} />
          ))}
        </div>
      </div>

      <div className="footer hidden">
        <a
          onClick={handlecklicContact}
          className="footer_text cursor_work"
          datatype="[CONTACT US]"
        >
          <h1>LET'S CREATE</h1>
          <h2>SOMETHING GREATE</h2>
          <h2>TOGETHER</h2>
        </a>
      </div>
    </div>
  );
}

export default Hosting;
