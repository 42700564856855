import React, { useState } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import "./FAQ.css"; // Import the CSS file for styling

const FAQ = () => {
  const faqData = [
    {
      question: "What services does LeadQode offer?",
      answer:
        "LeadQode provides a wide range of digital services, including web development, app development, hosting, graphic design, maintenance, and other related services. We specialize in creating custom websites, developing mobile applications for various platforms, designing visually appealing graphics, and ensuring the smooth operation of your digital presence.",
    },
    {
      question: "Can LeadQode handle both small and large-scale projects?",
      answer:
        " Absolutely! LeadQode caters to clients of all sizes, ranging from small businesses to large enterprises. We understand that each project is unique and requires a tailored approach. Our team is equipped to handle projects of any scale, ensuring that we deliver high-quality solutions regardless of the project's size or complexity.",
    },
    {
      question: "What technologies does LeadQode use for web development?",
      answer:
        "At LeadQode, we utilize a variety of technologies to develop robust and efficient websites and applications. Our primary web development stacks include MERN (MongoDB, Express.js, React.js, Node.js) and LARAVEL. These stacks offer flexibility, scalability, and excellent performance. Additionally, we have expertise in popular content management systems (CMS) such as WordPress and Shopify, enabling us to create dynamic and customizable websites according to your specific needs.",
    },
    {
        question: "Does LeadQode provide hosting services for websites and applications?",
        answer:
          "Yes, LeadQode offers hosting services for websites and applications. We understand the importance of reliable and secure hosting for your digital assets. Our hosting solutions are tailored to meet the specific needs of your website or application, ensuring optimal performance and uptime. We work with reputable hosting providers and offer various hosting options, including shared hosting, virtual private servers (VPS), and dedicated servers. Our team can assist you in selecting the right hosting plan that suits your requirements and budget.",
      },
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          className={`faq-item ${activeIndex === index ? "open" : ""}`}
          key={index}
          onClick={() => toggleAccordion(index)}
        >
          <div className="faq-question">
            {faq.question}
            {activeIndex === index ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          {activeIndex === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
