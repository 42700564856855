import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assets/LeadqodeWhite.png";
import "./Footer.css";
import Scroll from "../Scroll/Scroll";
import Share from "../Share/Share";
import Fadeonscroll from "../FadeOnScroll/Fadeonscroll";

function Footer() {
  const navigate = useNavigate();

  const logoclick = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
  

      <div className="copywrite-footer_desktop">
        <div className="footer_scroll">
          <Scroll />
        </div>
        <div className="copywrite">
          <p>© 2024 Leadqode All Rights Reserved.</p>
        </div>
        <div className="footer_share">
        <p><Share/> </p>  
        </div>
      </div>

      <div className="copywrite-footer_mobile">
    
       
        <div className="footer_share">
        <p> <div >
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="icon" size={30} />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="icon" size={30} />
        </a>
      </div> </p>  
        </div>

        <div className="copywrite">
          <p>© 2024 Leadqode All Rights Reserved.</p>
        </div>
      </div>




    </div>
  );
}

export default Footer;
