// Cursor.js
import React, { useEffect, useRef } from 'react';

const Cursor = () => {
  const cursorRef = useRef(null);

  const mouse = { x: 0, y: 0 };
  const pos = { x: 0, y: 0 };
  const ratio = 0.9;
  const defaultCursorRadius = 15;
  const linkHoverScale = 1.3;

  useEffect(() => {
    const cursorRadius = cursorRef.current ? Math.round(cursorRef.current.getBoundingClientRect().width / 2) : defaultCursorRadius;

    const draw = () => {
      const targetScale =  linkHoverScale ;
      const scaledCursorRadius = cursorRadius * targetScale;

      pos.x = mouse.x - scaledCursorRadius * ratio;
      pos.y = mouse.y - scaledCursorRadius * ratio;

      if (cursorRef.current) {
        cursorRef.current.style.transform = `translate3d(${pos.x}px, ${pos.y}px, 0) scale(${targetScale})`;
      }
    };

    const update = (e) => {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    };


    const animate = () => {
      draw();
      requestAnimationFrame(animate);
    };


    window.addEventListener('mousemove', update, { capture: false, passive: true });

    requestAnimationFrame(animate);

    return () => {
      window.removeEventListener('mousemove', update);
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return <div id="cursor" ref={cursorRef} style={{ position: 'fixed' }} />;
};

export default Cursor;
