import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Work.css";
import Loader from "../../../Components/Loader/Loader";
import Fadeonscroll from "../../../Components/FadeOnScroll/Fadeonscroll";
import { useNavigate } from "react-router-dom";

function Work() {

  const navigate =useNavigate();
  
  const handlecklicContact = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/contact-us");
    }, 1000);
  };
  const [alldata, setAlldata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [cardsPerPage, setCardsPerPage] = useState(9);
  const [visibleCards, setVisibleCards] = useState(9);

  const api = "https://api.leadqode.com/api/work/getallwork";
  const fetchWork = async () => {
    try {
      const response = await axios.get(api);
      const workData = response.data.work;

      // Sort the data in ascending order based on the 'createdAt' timestamp.
      workData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      setTimeout(() => {
        setAlldata(workData);
        setFilteredData(workData); // Initialize filtered data
        setIsLoading(false);
      }, 5000); // Delay for 5 seconds before displaying data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchWork();
  }, []);

  const filterDataByType = (type) => {
    const filteredData = alldata.filter((item) => item.type === type);
    setFilteredData(filteredData);
    setActiveTab(type);
    setVisibleCards(cardsPerPage); // Reset visible cards when applying a filter.
  };

  const showAllItems = () => {
    setFilteredData(alldata);
    setActiveTab("all");
    setVisibleCards(cardsPerPage); // Reset visible cards when showing all items.
  };

  const loadMore = async () => {
    setIsLoading(true); // Set loading state to true
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate a 2-second delay (you can remove this in the actual code)
    setVisibleCards((prevVisibleCards) => prevVisibleCards + cardsPerPage);
    setIsLoading(false); // Set loading state to false after loading more items
  };

  return (
    <div>
      <Fadeonscroll />
      <div className="overflow_hidden">
      <div className="page_title flex">
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            <span>OUR </span> <h1>WORK</h1>
            {/* Repeat span and h1 as needed */}
          </div>{" "}
          </div>
    
      <div className="work-container">
        <div className="tab-buttons">
          <button
            className={activeTab === "all" ? "active" : ""}
            onClick={showAllItems}
          >
            All
          </button>
          <button
            className={activeTab === "website" ? "active" : ""}
            onClick={() => filterDataByType("website")}
          >
            Website
          </button>
          <button
            className={activeTab === "socialmedia" ? "active" : ""}
            onClick={() => filterDataByType("socialmedia")}
          >
            Social Media
          </button>
          <button
            className={activeTab === "branding" ? "active" : ""}
            onClick={() => filterDataByType("branding")}
          >
            Branding
          </button>
        </div>
        {isLoading ? <Loader /> : null}
        <div className={`work-grid ${isLoading ? "loading" : ""}`}>
          {filteredData.slice(0, visibleCards).map((item, index) => (
            <a href={item.link} className="work-card" key={index}>
              <img loading="lazy" src={item.mainImage} alt={item.alt} />
              <h2>{item.title}</h2>
            </a>
          ))}
        </div>
        <br />
        {visibleCards < filteredData.length && (
          <button onClick={loadMore} className="button_web ">
            {isLoading ?  <Loader  /> : "Load More"}
          </button>
        )}
      </div>

      <div className="footer hidden">
        <a onClick={handlecklicContact}  className="footer_text cursor_work"
    datatype="[CONTACT US]">
          <h1>LET'S CREATE</h1>
          <h2>
            SOMETHING GREATE
          </h2>
          <h2>
           TOGETHER
          </h2>
        </a>
      </div>
    </div>
  );
}

export default Work;
