import React, { useEffect, useState } from 'react';
import './ChangingText.css';

const ChangingText = () => {
  const words = [
    'BUSINESS',
    'DEVELOPMENT',
    'HOSTING',
    'BRANDING',
    'DESIGN',
  
  ];
  const animationTimeInMs = 65;
  const delayInMs = 1000;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    const editable = document.querySelector('.changing-text');

    function close(index) {
      editable.textContent = words[index % words.length];
      const interval = setInterval(() => {
        const text = editable.textContent;
        if (!text) {
          clearInterval(interval);
          setTimeout(() => {
            open(++index);
          }, 100);
        }
        editable.textContent = text.slice(0, -1);
      }, animationTimeInMs);
    }

    function open(index) {
      const initialText = words[index % words.length];
      let i = 1;
      editable.textContent = '';
      const interval = setInterval(() => {
        const text = editable.textContent;
        if (text.length === initialText.length) {
          clearInterval(interval);
          setTimeout(() => {
            close(index);
          }, delayInMs);
        }
        editable.textContent = initialText.slice(0, i++);
      }, animationTimeInMs);
    }

    function writeWords() {
      const i = 0;
      open(i);
    }

    writeWords();
  }, []);

  return (
    <div className="code">
      <span className="bracket">{'{'}</span>
      <span className="changing-text">{displayText}</span>
      <span className="bracket">{'}'}</span>
    </div>
  );
};

export default ChangingText;
