import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import web1 from "../../Assets/web1.png";
import web2 from "../../Assets/web2.png";
import web3 from "../../Assets/web3.png";

import "./Home.css";
import ChangingText from "../../Components/Slider/ChangingText";
import Logos from "../../Components/Logos/Logos";
import Fadeonscroll from "../../Components/FadeOnScroll/Fadeonscroll";
import WorkCarousel from "../../Components/WorkCarousel/WorkCarousel";
import Services from "../../Components/Services/Services";
gsap.registerPlugin(ScrollTrigger);

const Section = ({ color, children }) => {
  return (
    <div className={`section color-${color}`} data-color={color}>
      <div className="full_row">{children} </div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const handleClickExplore = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/about-us");
    }, 1000);
  };
  const handlecklickhost = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/hosting");
    }, 1000);
  };
  const handleClickLead = () => {
    window.location.href = "https://ai.leadqode.com/";
  };
  useEffect(() => {
    // Animation logic
    gsap.utils.toArray(".section").forEach((section, i) => {
      if (section.getAttribute("data-color") !== null) {
        const colorAttr = section.getAttribute("data-color");

        gsap.to(".wrap", {
          backgroundColor:
            colorAttr === "dark"
              ? gsap.getProperty("html", "--dark")
              : gsap.getProperty("html", "--light"),
          immediateRender: false,
          scrollTrigger: {
            trigger: section,
            start: "center bottom",
            end: "+=100%",
            toggleActions: "play none none reverse",
          },
        });
      }
    });
  }, []);
  return (
    <div>
      <Fadeonscroll />
      <div className="hero hidden">
        <p>Let's Talk </p>
        <br />
        <br />
        <ChangingText />
      </div>
      <Logos />
      <section className="wrap">
        <Section color="dark">
          <div className="sections_container">
            <div className="hidden text_section ">
              <h1>
                <span className="brackets">{"["}</span>Who We Are
                <span className="brackets">{"]"}</span>{" "}
              </h1>

              <p>
                Leadqode is a top-tier global creative agency, specializing in
                advanced business solutions for brands worldwide. Our skilled
                team offers cost-effective services in web and app development,
                hosting, graphic design, and social media management. From large
                corporations to startups, we provide personalized attention and
                tailored solutions to enhance online presence and drive growth.
                Leadqode empowers businesses with innovative, high-quality
                solutions.
              </p>
              <br />
              <br />
              <div>
                <button className="button_web" onClick={handleClickExplore}>
                  Explore more
                </button>
              </div>
            </div>
            <div className="image_section fromright">
              <img loading="lazy" src={web1} alt="logo" />
            </div>
          </div>
        </Section>
        <Section color="light">
          <div className="sections_container mobile-flip">
            <div className="image_section fromright hidden">
              <img loading="lazy" src={web2} alt="logo" />
            </div>
            <div className="hidden text_section ">
              <h1>
                <span className="brackets">{"["}</span>Hosting Plans
                <span className="brackets">{"]"}</span>{" "}
              </h1>

              <p>
                Discover the ideal hosting plans for your business with
                Leadqode! Explore a variety of hosting options hassle-free.
                Establish a reliable online presence with just a few clicks.
                From shared hosting to dedicated servers, the choices are
                limitless. Dive into the world of hosting plans for your online
                success today!
              </p>
              <br />
              <br />
              <div>
                <button className="button_web_dark" onClick={handlecklickhost}>
                  Explore more
                </button>
              </div>
            </div>
          </div>
        </Section>
        <Section color="dark">
          <div className="sections_container">
            <div className="hidden text_section ">
              <h1>
                <span className="brackets">{"["}</span>Lead AI
                <span className="brackets">{"]"}</span>{" "}
              </h1>

              <p>
                Introducing Lead AI, the latest innovation from Leadqode for web
                development and beyond. Experience rapid, accurate responses
                that optimize user engagement and elevate customer support.
                Revolutionize your online experience with the power of Lead AI,
                setting new standards in efficiency and user satisfaction.
              </p>
              <br />
              <br />
              <div>
                <button className="button_web" onClick={handleClickLead}>
                  Explore more
                </button>
              </div>
            </div>
            <div className="image_section bouncing-element hidden">
              <img loading="lazy" src={web3} alt="web3" />
            </div>
          </div>
        </Section>

        <Section color="light">
          <div>
            <div className="hidden title_center ">
              <h1>
                <span className="brackets">{"["}</span>What We Do
                <span className="brackets">{"]"}</span>{" "}
              </h1>

              <div className="full_row">
                <Services />
              </div>
            </div>
          </div>
        </Section>
        <Section color="dark">

          <div className="work_carousel_home">
            <div className="blink marquee_right">
              <p>
                WORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKwork
              </p>
            </div>
            <WorkCarousel />
            <div className="blink marquee_left">
              <p>
                WORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKworkWORKwork
              </p>
            </div>
          </div>
        </Section>
      </section>

      <div className="footer hidden">
        <a
          onClick={handleClickExplore}
          className="footer_text cursor_work"
          datatype="[ABOUT US]"
        >
          <h1>DISCOVER</h1>
          <h2>OUR WORLD</h2>
        </a>
      </div>
    </div>
  );
};

export default Home;
