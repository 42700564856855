import { Route, Routes, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import Navbar from "./Components/Header/Navbar";
import Home from "./Pages/Home/Home";
import Services from "./Components/Services/Services";
import Cursor from "./Cursor";
import Hosting from "./Pages/Home/Hosting/Hosting";
import Work from "./Pages/Home/Work/Work";
import Contact from "./Pages/Home/ContactUs/Contact";
import Footer from "./Components/Footer/Footer";
import Aboutus from "./Pages/Home/AboutUs/Aboutus";




function App() {
  
  return (
    <div >
      <Cursor />
     

      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/hosting" element={<Hosting />} />
        <Route path="/our-work" element={<Work />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

