import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WorkCarousel.css';

const WorkCarousel = () => {
  const [leftToRightData, setLeftToRightData] = useState([]);
  const [rightToLeftData, setRightToLeftData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for left-to-right marquee
        const leftResponse = await axios.get('https://api.leadqode.com/api/work/getallwork');
        setLeftToRightData(leftResponse.data.work);

        // Fetch data for right-to-left marquee
        const rightResponse = await axios.get('https://api.leadqode.com/api/work/getallwork');
        setRightToLeftData(rightResponse.data.work);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="wrapper">
      <div className="marquee marquee--left-to-right">
        <div className="marquee__group">
          {leftToRightData.map((item, index) => (
            <div className="card_workcarousel" key={index}>
              <img loading='lazy' src={item.mainImage} alt={item.title} />
            </div>
          ))}
        </div>
      </div>

      <div className="marquee marquee--right-to-left">
        <div className="marquee__group">
          {rightToLeftData.map((item, index) => (
            <div className="card_workcarousel" key={index}>
              <img loading='lazy' src={item.mainImage} alt={item.title} />
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default WorkCarousel;