import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ServicesAbout.css";
import Fadeonscroll from "../FadeOnScroll/Fadeonscroll";

function ServicesAbout() {
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.leadqode.com/api/info/getallinfo")
      .then((response) => {
        const servicesInfo = response.data.filter(
          (info) => info.type === "services"
        );
        setServicesData(servicesInfo);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
    <div className="servicesabout_container">
      
      {servicesData
        .filter((serviceInfo) => serviceInfo.type === "services")
        .map((serviceInfo) => (
          <div  key={serviceInfo._id}>
          
              <div className="servicesabout " >
                <h3>  <span>{serviceInfo.details[0].subtitle}</span> {serviceInfo.details[0].title}</h3>
                <p>  {serviceInfo.details[0].description}</p>
              </div>
           
          </div>
        ))}
    </div>
    </>
  );
}
export default ServicesAbout;
