import React, { useState } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { IoMdShare } from "react-icons/io";

import "./Share.css";

const Share = () => {
  const [isHovered, setHovered] = useState(false);

  const handleButtonClick = () => {
    // Handle button click event if needed
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <span
      className="follow-button"
      onClick={handleButtonClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='text__icon'>
      <span>Follow Us</span>
      <IoMdShare className={`share-icon ${isHovered ? 'visible' : ''}`} size={30} />
      </div>
      <div className={`social-icons-container ${isHovered ? 'visible' : ''}`}>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="icon" size={30} />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="icon" size={30} />
        </a>
      </div>
    </span>
  );
};

export default Share;
