// Navbar.jsx
import React, { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";

import "./Navbar.css";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import Logo from "../../Assets/LeadqodeWhite.png";
import Logodark from "../../Assets/Leadqodedark.png";
import { Link, useNavigate } from "react-router-dom";
import Toggle from "../ToggleTheme/Toggle";
import { useTheme } from '../../ThemeContext';

const Navbar = () => {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const isHomePage = location.pathname === "/";
  const { theme, toggleTheme } = useTheme();

  const navigate = useNavigate();

  const handleClick = () => {
    setClicked(!clicked);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };



  const logoclick = () => {
    navigate("/");
    window.location.reload();
  };
  useEffect(() => {
  }, [toggleTheme]);


  return (
    <div  style={{ backgroundColor: theme , position:"relative",zIndex:"1111" }}>
      <nav className="navbar_nav">
      <img loading="lazy" className="img_header" src={theme === 'dark-theme' ? Logo : Logodark} onClick={logoclick} alt="logo" />

        <div className="menu">
          <ul id="navbar" className={clicked ? "#navbar_ active" : "#navbar"}>
            <li onClick={logoclick}>
              <Link to="/" className="active">
                Home
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/about-us">About us</Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/our-work">Our Work</Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/hosting">Hosting</Link>
            </li>

            <li onClick={handleClick}>
              <Link to="/contact-us">Support</Link>
            </li>
          </ul>
          {!isHomePage &&  <div><Toggle toggleTheme={toggleTheme} /></div>}
        </div>

        <div id="mobile" onClick={handleClick}>
          <HiMenuAlt3 id="bars" className={clicked ? <HiMenuAlt3/> : <IoClose />}/>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
