import React from "react";
import Fadeonscroll from "../../../Components/FadeOnScroll/Fadeonscroll";
import aboutus1 from "../../../Assets/aboutus1.png";
import mission from "../../../Assets/mission.png";
import vision from "../../../Assets/vision.png";
import ServicesAbout from "../../../Components/ServicesAbout/ServicesAbout";
import "./Aboutus.css";
import { useNavigate } from "react-router-dom";

function Aboutus() {
  const navigate = useNavigate();

  const handlecklickwork = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/our-work");
    }, 1000);
  };
  return (
    <div>
      <Fadeonscroll />
      <div className="overflow_hidden">
      <div className="page_title flex">
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
  <span>ABOUT </span>
  <h1>LEADQODE</h1>
</div>
</div>
      <div className="sections_container">
        <div className="hidden text_section ">
          <h1>
            <span className="brackets">{"["}</span>Know Us More
            <span className="brackets">{"]"}</span>{" "}
          </h1>

          <p>
            Our new world resides online, that’s why we are here for you, we’ll
            Lead you to greatness, Lead Qode has your back. As a team of
            experienced designers, developers, and creators who want to achieve
            something, our duty is to help you achieve your goals. Whether you
            have a big firm or an online shop we can help you. From your Logo,
            to your website, and even to the design of every one of your posts.
            We’ll help you calculate your every move so you can place your name
            and optimize your growth on the market.
          </p>
          <br />
          <br />
        </div>
        <div className="image_section fromright">
          <img loading="lazy"  src={aboutus1} alt="logo" />
        </div>
      </div>
      <div className="sections_container">
        <div className="hidden text_section ">
          <h1>
            <span className="brackets">{"["}</span>Mission
            <span className="brackets">{"]"}</span>{" "}
          </h1>

          <p>
            Our mission is to guide individuals and businesses to digital
            success. Our experienced team of designers, developers, and creators
            is dedicated to helping you achieve your goals, whether you're a
            large corporation or an online retailer. From crafting logos and
            websites to refining post designs, we strategically assist you in
            optimizing your brand's growth in the dynamic market.
          </p>
          <br />

          <br />
        </div>
        <div className="image_section fromright">
          <img loading="lazy" src={mission} alt="logo" />
        </div>
      </div>
      <div className="sections_container">
        <div className="hidden text_section ">
          <h1>
            <span className="brackets">{"["}</span>Vision
            <span className="brackets">{"]"}</span>{" "}
          </h1>

          <p>
            Our vision is to be the foremost partner for individuals and
            businesses navigating the online realm. We aspire to be a catalyst
            for success, offering tailored solutions that go beyond an online
            presence, creating a lasting digital footprint. LeadQode aims to be
            synonymous with innovation, excellence, and unwavering support as we
            contribute to the growth and evolution of our clients in the digital
            landscape.
          </p>
          <br />
          <br />
        </div>
        <div className="image_section fromright">
          <img loading="lazy" src={vision} alt="logo" />
        </div>
      </div>

      <div className="hidden title_center serviceaboutpage ">
        <h1>
          <span className="brackets">{"["}</span>Our Services
          <span className="brackets">{"]"}</span>{" "}
        </h1>

        <ServicesAbout />
      </div>

      <div className="footer hidden">
        <a
          onClick={handlecklickwork}
          className="footer_text cursor_work"
          datatype="[OUR WORK]"
        >
          <h1>EXPLORE</h1>
          <h2>OUR PORTFOLIO</h2>
        </a>
      </div>
    </div>
  );
}

export default Aboutus;
