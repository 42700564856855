import React, { useRef, useEffect } from 'react';
import './Logos.css';
import github from "../../Assets/logos/github.png";
import laravel from "../../Assets/logos/laravel.png";
import mongodb from "../../Assets/logos/mongo.png";
import reactjs from "../../Assets/logos/react.png";
import nodejs from "../../Assets/logos/node.png";
import woo from "../../Assets/logos/woo.png";
import shopify from "../../Assets/logos/shopify.png";
import wordpress from "../../Assets/logos/wordpress.png";
import firebase from "../../Assets/logos/firebase.png";
import docker from "../../Assets/logos/docker.png";
import cpanel from "../../Assets/logos/cpanel.png";
import adobe from "../../Assets/logos/adobe.png";

const Logos = () => {
  const slideRef = useRef(null);

  useEffect(() => {
    const slideContainer = slideRef.current;
    const cloneContainer = slideContainer.cloneNode(true);
    slideContainer.parentNode.appendChild(cloneContainer);

    const animate = () => {
      slideContainer.style.transform = 'translateX(-100%)';
      slideContainer.style.transition = 'transform 35s linear';

      const handleTransitionEnd = () => {
        slideContainer.style.transition = 'none';
        slideContainer.style.transform = 'translateX(0)';
        requestAnimationFrame(() => {
          requestAnimationFrame(animate);
        });
        slideContainer.removeEventListener('transitionend', handleTransitionEnd);
      };

      slideContainer.addEventListener('transitionend', handleTransitionEnd);
    };

    requestAnimationFrame(animate);

    return () => {
      slideContainer.parentNode.removeChild(cloneContainer);
    };
  }, []);

  return (
    <div className="logos">
      <div ref={slideRef} className="logos-slide">
        <img loading='lazy' src={github} alt="github" />
        <img loading='lazy'src={laravel} alt="laravel" />
        <img loading='lazy'src={mongodb} alt="mongodb" />
        <img loading='lazy'src={reactjs} alt="reactjs" />
        <img loading='lazy'src={nodejs} alt="nodejs" />
        <img loading='lazy'src={woo} alt="woo" />
        <img loading='lazy'src={shopify} alt="shopify" />
        <img loading='lazy'src={wordpress} alt="wordpress" />
        <img loading='lazy'src={firebase} alt="firebase" />
        <img loading='lazy'src={docker} alt="docker" />
        <img loading='lazy'src={cpanel} alt="cpanel" />
        <img loading='lazy'src={adobe} alt="adobe" />
      </div>
    </div>
  );
};

export default Logos;
