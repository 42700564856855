import React from 'react';
import "./Loader.css";

function Loader() {
  return (
    <div className="loader-container">
<div class="loader">
        <li class="dots" id="dot-1"></li>
        <li class="dots" id="dot-2"></li>
        <li class="dots" id="dot-3"></li>
    </div>
    </div>
  );
}

export default Loader;
