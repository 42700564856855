import React from "react";
import "./Toggle.css";
import { FaMoon, FaSun } from "react-icons/fa";
import { useState } from "react";

function Toggle(props) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleToggleTheme = () => {
    props.toggleTheme();
    setIsDarkMode(!isDarkMode);
  };
  return (
    <>
      <label className={`toggle-theme-button ${isDarkMode ? "dark" : "light"}`}>
        <input type="checkbox" onChange={handleToggleTheme} />
        <span className="slider">
          <span className="slider-before"></span>
        </span>
        {isDarkMode ? <FaSun className="icon" /> : <FaMoon className="icon" />}
      </label>
    </>
  );
}

export default Toggle;
