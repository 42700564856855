import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Contact.css";

import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import Fadeonscroll from "../../../Components/FadeOnScroll/Fadeonscroll";
import FAQ from "../../../Components/FAQ/FAQ";
import { useNavigate } from "react-router-dom";
// import Fadeonscroll from "../../components/FadeOnScroll/Fadeonscroll";
const Contact = () => {

  const navigate =useNavigate();
  
  const handlecklicContact = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/contact-us");
    }, 1000);
  };

  const [namecontact, setNameContact] = useState("");
  const [emailcontact, setEmailContact] = useState("");
  const [messagecontact, setMessageContact] = useState("");
  const [phoneNumbercontact, setPhoneNumberContact] = useState(null);
  const form = useRef();
  const handleEvent = async (e) => {
    e.preventDefault();
    var data = {
      namecontact,
      emailcontact,
      messagecontact,
      phoneNumbercontact,
    };

    try {
      await emailjs.send(
        "service_m8ltmxq",
        "template_8mslk0o",
        data,
        "-KXWhlaVZFScEBVgb"
      );

      toast.success("Form submitted successfully!");

      setNameContact("");
      setEmailContact("");
      setMessageContact("");
      setPhoneNumberContact("");
    } catch (error) {
      toast.error("Failed to submit form. Please try again later.");
    }
  };

  return (
    <>
      <Fadeonscroll />
      <div>
        <Fadeonscroll />
        <div className="overflow_hidden">
        <div className="page_title flex">
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>{" "}
              <span>LET'S </span> <h1>CREATE</h1> <span>TOGETHER </span>
            </div>{" "}
  </div>

       <div className="contact_faq_container">
        <div className="form_contact_container hidden">
          <form ref={form} className="form_contact " onSubmit={handleEvent}>
          <div className="hidden text_section contact_section ">
            <h1>
                <span >{"[ "}</span>Connect with Us
                <span >{" ]"}</span>{" "}
              </h1>
            <p>Thank you for considering LEADQODE as your digital company.</p>
            <p>
              Please complete the details below and then click on Submit and
              we'll be in contact
            </p>
            </div>
            <div className="contact_form_input">
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={namecontact}
                  onChange={(e) => {
                    setNameContact(e.target.value);
                  }}
                  required
                />
              </div>

              <div>
                <input
                  type="text"
                  name="email"
                  placeholder="Your Email"
                  value={emailcontact}
                  onChange={(e) => {
                    setEmailContact(e.target.value);
                  }}
                  required
                />
              </div>
          

            <div className="contact_form_input">
              <div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={phoneNumbercontact}
                  onChange={(e) => {
                    setPhoneNumberContact(e.target.value);
                  }}
                  required
                />
              </div>
              <div>
                <input
                  name="message"
                  type="textarea"
                  placeholder="Your Message"
                  value={messagecontact}
                  onChange={(e) => {
                    setMessageContact(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            </div>
          
            <div className="button_contactform">
              <button className="domain_btn" type="submit" id="form_button">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="faq_contactus ">
        <div className="hidden text_section contact_section">
        <h1>
                <span >{"[ "}</span>How can we help you
                <span >{" ]"}</span>{" "}

              </h1>
            <p>Below you'll ffind answers to the questions we get asked the most about LeadQode</p>
            

        </div>
           
          <FAQ/>
        </div>

        </div>
        <ToastContainer position="bottom-right" />
      </div>
      <div className="footer hidden">
          <a onClick={handlecklicContact} className="footer_text">
            <h1>LET'S PLAN</h1>
            <h2>YOUR BUSINESS</h2>
            <h2>TOGETHER</h2>
          </a>
        </div>

    </>
  );
};

export default Contact;
